import React from 'react'
import Hero from '../components/Hero/hero'
import Layout from '../components/Layout/layout'
import Thankyou from '../components/Thankyou/Thankyou'

const ThankYouPage = () => {
  return (
   <Layout>
        <Thankyou />
    </Layout>
  )
}

export default ThankYouPage