import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./thankyou.css";

const Thankyou = () => {
  return (
    <div className="container hero">
      <div className="img-fluid dots">
        <StaticImage src="../../images/dots.png" alt="dots" />
      </div>
      <div className="flex-column align-items-center d-flex justify-content-center">
        <div className="img-fluid position-relative">
          <StaticImage src="../../images/mail.png" alt="contactus" />
        </div>
        <div>
          <p className="text">Thank You</p>
        </div>
        <div>
          <button
            className="sib-form-block__button sib-form-block__button-with-loader hero__button"
            form="sib-form"
            type="submit"
            onClick={() => navigate("/")}
          >
            <span className="btn-text">RETURN BACK</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
